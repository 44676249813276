import * as React from 'react';

type Props = {
  className?: string;
};

const EmailIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    overflow="visible"
    width="64px"
    height="64px"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(7.000000, 11.000000)" fill="#1A1919">
        <path d="M43.776,0 L6.912,0 C3.094848,0 0,3.094848 0,6.912 L0,34.56 C0,38.377152 3.094848,41.472 6.912,41.472 L43.776,41.472 C47.593152,41.472 50.688,38.377152 50.688,34.56 L50.688,6.912 C50.688,3.094848 47.593152,0 43.776,0 Z M43.776,4.608 C44.040384,4.608 44.290368,4.661568 44.526528,4.74336 L25.344,20.089152 L6.161472,4.74336 C6.398208,4.661568 6.647616,4.608 6.912,4.608 L43.776,4.608 Z M43.776,36.864 L6.912,36.864 C5.63904,36.864 4.608,35.83296 4.608,34.56 L4.608,9.402048 L23.904576,24.838848 C24.325056,25.175808 24.834816,25.344 25.344,25.344 C25.853184,25.344 26.362944,25.175808 26.783424,24.838848 L46.08,9.402048 L46.08,34.56 C46.08,35.83296 45.04896,36.864 43.776,36.864 Z" />
      </g>
    </g>
  </svg>
);

export default EmailIcon;
